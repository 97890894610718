import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, Box, Grid, Typography, Dialog, DialogActions, DialogTitle, FormControl,
   InputLabel,  Select, MenuItem, TextField
} from '@mui/material';
import { Modal } from 'antd';
import { Container, FormDiv } from "../styles/Product.style";
import { useParams } from 'react-router-dom';
import ProductSearch from "./ProductSearch";
import OrderProductBox from "./OrderProductBox";
import { get_product_search } from "../services/order";
import { calculatePrice } from "../constants/index";
import AlreadyProductOfUpdate from "./AlreadyProductOfUpdate";

const OrderDepartmentQuotation = ({gstRates,companies,units,orderProductss,
    department,setDepartment,departments,duplicates,alreadyAddedQuotationPro}:any) => {
  const [modal, contextHolder] = Modal.useModal();
  const { orderId } = useParams();
  const [visibleProducts, setVisibleProducts] = useState(25);
  const [errorMessage, setErrorMessage] = useState("");
  const [withGst, setWithGst] = useState(2);
  const [orderProducts, setOrderProducts] = useState<any>([...orderProductss]);
  const [companyId, setCompanyId] = useState("1000000000");
  const [openError, setOpenError] = useState(false);
  const [orderDepartmentSequence,setOrderDepartmentSequence] = useState(department?.orderSequence ? department?.orderSequence : "")
  const [deptEmpMobileNo,setDeptEmpMobileNo] = useState(department?.deptEmpMobileNo ? department?.deptEmpMobileNo : "")
  const [deptEmpName,setDeptEmpName] = useState(department?.deptEmpName ? department?.deptEmpName : "")
  console.log("department?.deptEmpName",department?.deptEmpName, department?.orderSequence)
  useEffect(()=>{
    setOrderProducts(orderProductss)
    setOrderDepartmentSequence(department?.orderSequence ? department?.orderSequence : "")
    setDeptEmpName(department?.deptEmpName ? department?.deptEmpName : "")
    setDeptEmpMobileNo(department?.deptEmpMobileNo ? department?.deptEmpMobileNo : "")
  },[department.id]);
 
   // Track changes in orderProducts and update department.orderProducts
   useEffect(() => {
    const updatedDepartments = departments.map((dept: any) => {
      if (dept.id === department.id) {
        // Update only the matching department
        return {
          ...dept,
          orderProducts: orderProducts,
        };
      }
      return dept;
    });

    // Update the departments state
    setDepartment(updatedDepartments);
  }, [orderProducts]);
  const onChangeOrderSequence = (e:any) => {

    setOrderDepartmentSequence(e.target.value)
    let newDepartments = departments.map((dept:any)=>{
      if (dept.id === department.id) {
        // Update only the matching department
        return {
          ...dept,
          orderSequence :e.target.value
        };
      }
      return dept;
    })
    setDepartment(newDepartments);
  }
  const onChangeDeptEmpName = (e:any) => {

    setDeptEmpName(e.target.value)
    let newDepartments = departments.map((dept:any)=>{
      if (dept.id === department.id) {
        // Update only the matching department
        return {
          ...dept,
          deptEmpName :e.target.value
        };
      }
      return dept;
    })
    setDepartment(newDepartments);
  }
  const onChangeDeptEmpMobileNo = (e:any) => {

    setDeptEmpMobileNo(e.target.value)
    let newDepartments = departments.map((dept:any)=>{
      if (dept.id === department.id) {
        // Update only the matching department
        return {
          ...dept,
          deptEmpMobileNo :e.target.value
        };
      }
      return dept;
    })
    setDepartment(newDepartments);
  }
  const onSelectToOrder = (product: any) => {
    setErrorMessage("")
    let exitingProduct = orderProducts.find((e: any) => e.attribute.id == product.attribute.id)
    if (!exitingProduct || true) {
      let srno = alreadyAddedQuotationPro && alreadyAddedQuotationPro.length > 0  ?  
      (alreadyAddedQuotationPro[alreadyAddedQuotationPro.length - 1].attribute.srno > 0 ?
        (alreadyAddedQuotationPro[alreadyAddedQuotationPro.length - 1].attribute.srno + orderProducts.length + 1) 
        : ( orderProducts.length + 1))
      : (orderProducts.length + 1)

      let attributes = { ...product.attribute, srno: product.attribute?.srno > 0 ? product.attribute?.srno :
            srno };

      let newProduct: any = { ...product, attribute: attributes }; // Create a copy of the product
      newProduct.quotationQty = 1;
      console.log("newProduct",newProduct)
      let newObj = calculatePrice(attributes, newProduct.quotationQty)
      newProduct.attribute = newObj;
      let updatedOrderProducts = [...orderProducts, newProduct]; // Copy the existing orderProducts array
      setOrderProducts([...updatedOrderProducts]);
    }

  }

  const deleteOrderedProduct = useCallback(
    (attributeId: number, indexToRemove: number) => {
      setOrderProducts((prevProducts: any) =>
        prevProducts.filter(
          (e: any, index: number) =>
            !(e.attribute.id === attributeId && index === indexToRemove)
        )
      );
    },
    []
  );
  const handleCloseError = () => {
    setOpenError(false);
  };
 

  const onUnitChange = useCallback(
    async (product: any, unitvalue: any) => {
      const config = {
        title: 'The product quantity you entered or the selected unit associated with the company is not available.',
      };
      let response: any = await get_product_search("", product.attribute.companyId, product.id, unitvalue, product.attribute.qty);
      if (response && response.data?.data?.length && response.data?.data[0].product_attributes
        && response.data?.data[0].product_attributes.length > 0) {
        let newProduct = {
          companyProductCode: response.data?.data[0].companyProductCode,
          id: response.data?.data[0].id,
          quotationQty: 1,
          name: response.data?.data[0].productName,
          attribute: response.data?.data[0].product_attributes[0]
        };
        setOrderProducts((prevProducts: any) => {
          let isUpdated = false;

          const updatedProducts = prevProducts.map((e: any) => {
            if (e.attribute.id === product.attribute.id &&
               (e.attribute.srno ? e.attribute.srno == product.attribute.srno: true)) {
              const updatedAttribute = {
                ...newProduct.attribute,
                srno:product.attribute.srno,
                isUpdate:
                  orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
              };

              isUpdated = true;
              return { ...newProduct, attribute: updatedAttribute };
            }
            return e;
          });

          // Only update state if there was a change
          return isUpdated ? updatedProducts : prevProducts;
        });
      } else {
        modal.warning(config);
      }
     
    },
    [orderId]
  );



  const onUnitQtyChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts: any) => {
        let isUpdated = false;

        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id && 
            (e.attribute.srno ? e.attribute.srno == product.attribute.srno: true)
          ) {
            // Check if the value is actually changing
            if (e.attribute.qty === value) {
              return e; // No change needed
            }

            const updatedAttribute = {
              ...e.attribute,
              qty: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };

            isUpdated = true;
            return { ...e, attribute: updatedAttribute };
          }
          return e;
        });

        // Only update state if there was a change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId]
  );
  const onUnitQtyChangeGetPrice = useCallback(
    async (product: any, value: any) => {
      const config = {
        title: 'The product quantity you entered or the selected unit associated with the company is not available.',
      };
      let response: any = await get_product_search("", product.attribute.companyId, product.id, product.attribute.unitId, value);
      if (response && response.data?.data?.length && response.data?.data[0].product_attributes
        && response.data?.data[0].product_attributes.length > 0) {
        let newProduct = {
          companyProductCode: response.data?.data[0].companyProductCode,
          id: response.data?.data[0].id,
          quotationQty: 1,
          name: response.data?.data[0].productName,
          attribute: response.data?.data[0].product_attributes[0]
        };
        setOrderProducts((prevProducts: any) => {
          let isUpdated = false;

          const updatedProducts = prevProducts.map((e: any) => {
            if (e.attribute.id === product.attribute.id &&
              (e.attribute.srno ? e.attribute.srno == product.attribute.srno: true)
            ) {
              const updatedAttribute = {
                ...newProduct.attribute,
                srno:product.attribute.srno,
                isUpdate:
                  orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
              };

              isUpdated = true;
              return { ...newProduct, attribute: updatedAttribute };
            }
            return e;
          });

          // Only update state if there was a change
          return isUpdated ? updatedProducts : prevProducts;
        });
      } else {
        modal.warning(config);
      }
     
    },
    [orderId]
  );

  const updatequotationQty = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts: any) => {
        let isUpdated = false;

        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id) {
            // Check if the value is actually changing
            if (e.quotationQty === value) {
              return e; // No change needed
            }

            // Update the attribute and quotationQty
            const updatedAttribute = {
              ...e.attribute,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };
            const newObj = calculatePrice(updatedAttribute, value);

            isUpdated = true;
            return {
              ...e,
              attribute: newObj,
              quotationQty: value,
            };
          }
          return e;
        });

        // Only update state if there was a change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );


  const onGstChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts: any) => {
        let isUpdated = false;

        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id &&
            (e.attribute.srno ? e.attribute.srno == product.attribute.srno: true)
          ) {
            // Check if the GST percentage is actually changing
            if (e.attribute.gst_percentage === value) {
              return e; // No change needed
            }

            // Mark as updated if necessary
            const updatedAttribute = {
              ...e.attribute,
              gst_percentage: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };

            // Recalculate the price based on the updated attribute
            const newObj = calculatePrice(updatedAttribute, e.quotationQty);

            isUpdated = true;
            return { ...e, attribute: newObj };
          }
          return e;
        });

        // Only update the state if there was an actual change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );

  const onPriceChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts: any) => {
        let isUpdated = false;

        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id) {
            // Check if the price is actually changing
            if (e.attribute.price === value) {
              return e; // No change needed
            }

            // Mark as updated if necessary
            const updatedAttribute = {
              ...e.attribute,
              price: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };

            // Recalculate the price
            const newObj = calculatePrice(updatedAttribute, e.quotationQty);

            isUpdated = true;
            return { ...e, attribute: newObj };
          }
          return e;
        });

        // Only update state if there was a change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );


  const onDiscountChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts: any) => {
        let isUpdated = false;

        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id &&
            (e.attribute.srno ? e.attribute.srno == product.attribute.srno: true)
          ) {
            // Check if the discount value is actually changing
            if (e.attribute.discount === value) {
              return e; // No change needed
            }

            // Mark as updated if necessary
            const updatedAttribute = {
              ...e.attribute,
              discount: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };

            // Recalculate the price based on the updated attribute
            const newObj = calculatePrice(updatedAttribute, e.quotationQty);

            isUpdated = true;
            return { ...e, attribute: newObj };
          }
          return e;
        });

        // Only update the state if there was an actual change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );



  
  // Function to check if a given pair exists in duplicateIndices
  function isDuplicate(product: any) {
    return duplicates.some(
      (duplicate: any) => duplicate.attribute.id === product.attribute.id
    );
  }
  const handleLoadMore = (isReset: boolean = false) => {
    if (isReset) {
      setVisibleProducts(25);
    } else {
      setVisibleProducts(prevCount => prevCount + 25);
    }
  };
  return (
    <Container>
      {contextHolder}
      <div style={{display:"flex"}}>
         <TextField
                label={<>
                 Order Sequence
                </>}
                style={{width:"30%"}}
                id="Order Sequence"
                type="number"
                size="small"
                value={orderDepartmentSequence}
                onChange={(e: any) => onChangeOrderSequence(e) }
                variant="outlined"
                onWheel={(e) => {
                  (e.target as HTMLInputElement).blur(); // ✅ TypeScript Safe
                }}
              /> &nbsp;
          <TextField
                label={<>
                 Department Emp. Name
                </>}
                style={{width:"30%"}}
                 id="Department Emp Name"
                size="small"
                value={deptEmpName}
                onChange={(e: any) => onChangeDeptEmpName(e) }
                variant="outlined"

              />&nbsp;
          <TextField
                label={<>
              Department Emp Mobile No.
                </>}
                style={{width:"30%"}}
                 id="  Department Emp Mobile No."
                size="small"
                type={"number"}
                value={deptEmpMobileNo}
                onChange={(e: any) => onChangeDeptEmpMobileNo(e) }
                variant="outlined"

              />
                          
       
      </div>
    
     

        <Grid container spacing={2}>
          <Grid item sm={12} md={12} lg={12}>
            
            {
              alreadyAddedQuotationPro && alreadyAddedQuotationPro.length > 0 && <>
                  <AlreadyProductOfUpdate alreadyAddedQuotationPro={alreadyAddedQuotationPro}
              onSelectToOrder={onSelectToOrder}
              departmentId={department.id}
              /> 
              </>

            } 
            
            <div style={orderProducts && orderProducts.length > 0 ?
              { width: "100%", height: "600px", overflowY: "auto" } : {}}>

              {
                orderProducts && orderProducts.length > 0 ?
                  orderProducts.slice(0, visibleProducts).map((e: any, index: number) => {
                    return <OrderProductBox key={`orderProductBox${index}`} product={e}
                      onQtyChange={updatequotationQty}
                      deleteOrderedProduct={deleteOrderedProduct} units={units}
                      onUnitChange={onUnitChange} gstRates={gstRates} onGstChange={onGstChange}
                      onUnitQtyChange={onUnitQtyChange}
                      onUnitQtyChangeGetPrice={onUnitQtyChangeGetPrice}
                      onPriceChange={onPriceChange}
                      onDiscountChange={onDiscountChange}
                      isDuplicate={isDuplicate}
                      index={index}
                      orderId={orderId}
                    />
                  })
                  : null
              }

            </div>
            {/* Load More Button */}
            <br />
            {orderProducts && orderProducts.length > visibleProducts && (
              <>

                <Button variant="outlined" onClick={() => handleLoadMore(false)} size={"small"} > Load More Products</Button>

                <Button variant="text" onClick={() => handleLoadMore(true)} size={"small"} style={{ color: "#9c0031" }} > Reset</Button>
                <label>{orderProducts.length - orderProducts.slice(0, visibleProducts).length} Products remaining to shown</label>
              </>
            )}
          </Grid>

        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={12} md={1}>
            <FormControl fullWidth size="small">
              <InputLabel>
                Company
              </InputLabel>
              <Select
                name="companyId"
                label="Company *"
                value={companyId}
                onChange={(e: any) => setCompanyId(e.target.value)}
                fullWidth
              >
                <MenuItem value={"1000000000"} key={"companies" + 3355555}>None</MenuItem>
                {
                  companies && companies.length > 0 && (
                    companies.map((e: any, index: number) => {
                      return (
                        <MenuItem value={e.id} key={"companies" + index}>{e.name}</MenuItem>
                      )
                    })
                  )
                }

              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={11}>
            <ProductSearch onSelectToOrder={onSelectToOrder}
              orderProducts={orderProducts}
              setOrderProducts={setOrderProducts}
              units={units}
              withGst={withGst}
              companyId={companyId} />

          </Grid>

        </Grid>
      
        <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
          {
            orderProducts && orderProducts.length > 0 ?
              <>
                <Typography variant="body1" sx={{ marginRight: "20x" }} >
                  Qty: <b>
                    {orderProducts.reduce((total: any, product: any) => {
                      const quotationQty = Number(product.quotationQty);
                      return Number(total) + quotationQty;
                    }, 0)}
                  </b>
                  &nbsp; &nbsp;
                  Total: <b>{(orderProducts.reduce((total: any, product: any) => Number(total) +
                    (Number(product.attribute.totalPrice)), 0)).toFixed(2)}</b>
                </Typography>

              </> : null
          }

        </Box>
      
      <Dialog
        open={openError}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Product qty is out of stock.
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseError}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


     
    </Container>
  );
};
export default OrderDepartmentQuotation;
