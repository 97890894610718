import React, { useState,useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button,
   Card, CardContent, TextField,FormControl,InputLabel,Select,MenuItem } from "@mui/material";
import {quotation_discount_update_api} from "../services/order";
import { get_companies, get_categories} from "../../product/services/product";
const DiscountDialog = ({ open, onClose, quotationId }:any) => {
  const [existingDiscount, setExistingDiscount] = useState("");   
  const [newDiscount, setNewDiscount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
 const [companyId, setCompanyId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [companies, setCompanies] = useState([]);
  const handleSave = async () => {
    if (!newDiscount || !quotationId || !companyId || !categoryId ) {
      setError("New discount and Quotation ID are required.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response:any = await quotation_discount_update_api({
        discount: newDiscount,
        oldDiscount: existingDiscount,
        quotationId,
        companyId,
        categoryId
      });
      window.location.reload();
      console.log("Response:", response.data);
      onClose(true); // Close dialog after success
    } catch (err) {
      console.error("Error updating discount:", err);
      setError("Failed to update discount. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const onChangeCategoryId = (e: any) => {
    setCategoryId(e.target.value)
  }
   useEffect(() => {
      fetch_companies();
      fetch_categories();
    }, []);
  
    const fetch_categories = async () => {
      try {
        const response: any = await get_categories();
        setCategories(response.data.data)
      } catch (error) {
      }
    }
     const fetch_companies = async () => {
        try {
          const response: any = await get_companies();
          setCompanies(response.data.data)
        } catch (error) {
        }
      }
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth="sm">
      <DialogTitle>Discount update of all existing quotation products</DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
          {error && <p style={{ color: "red" }}>{error}</p>}
            <TextField
            size="small"
              label="Existing Discount Value"
              value={existingDiscount}
              onChange={(e) => setExistingDiscount(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
            size="small"
              label="New Discount Value"
              value={newDiscount}
              onChange={(e) => setNewDiscount(e.target.value)}
              fullWidth
              margin="normal"
            />
              <FormControl style={{ width: "150px", marginRight: "20px" }} size="small">
        <InputLabel>
          Company
        </InputLabel>
        <Select
          size="small"
          name="company"
          value={companyId}
         label="Company"
          onChange={(e) => {
            setCompanyId(e.target.value)
         
          }}
        >
          {companies.map((e: any, indexG: any) => (
            <MenuItem value={e.id} key={"company" + indexG}>{e.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ width: "200px", marginRight: "10px" }} size="small">
        <InputLabel>
          Category <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <Select
          name="categoryId"
          label="Category *"
          value={categoryId}
          disabled={loading}
          onChange={onChangeCategoryId}
          fullWidth
        >{
            categories && categories.length > 0 && (
              categories.map((e: any, index: number) => {
                return (
                  <MenuItem value={e.id} key={"category" + index}>{e.name}</MenuItem>
                )
              })
            )
          }
        </Select>
      </FormControl>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
      <Button onClick={() => onClose(false)} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountDialog;
