import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Rectangle,ResponsiveContainer
} from "recharts";
import { useNavigate } from "react-router-dom";

interface OrderPerDayProps {
    data: any[];
}

export default function OrderPerDay({ data }: OrderPerDayProps) {
  const navigate = useNavigate();
  const handleBarClick = (data: any, index: number) => {
    if (!data || !data.orderDayNumber) {
        console.error("Invalid data or orderDayNumber not found");
        return;
    }

    const orderDayNumber = data.orderDayNumber; // The day of the month (e.g., 4)
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth(); // 0-based index (e.g., 0 = January)

    // Create start timestamp for the selected day (correct time zone handling)
    const startOfDay = new Date(currentYear, currentMonth, orderDayNumber + 1, 0, 0, 0, 0);
    const startTimestamp = startOfDay.getTime(); // Timestamp for 00:00:00 of the selected day

    // Create start timestamp for the next day (used as the end timestamp for the current day)
    const startOfNextDay = new Date(currentYear, currentMonth, orderDayNumber + 3, 0, 0, 0, 0);
    const endTimestamp = startOfNextDay.getTime(); // Timestamp for 00:00:00 of the next day

   
    // Redirect to the desired route with start and end timestamps
    navigate(`/admin/quotation_list/${orderDayNumber}/${currentMonth+1}/${currentYear}`);
};



    return (
        data && (
          <ResponsiveContainer width="100%" height={300}> 
            <BarChart
                // width={700}
                // height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="orderDayNumber" />
                <YAxis />
                <Tooltip />
                <Bar
                    dataKey="quotationCount"
                    fill="#B3CDAD"
                    activeBar={<Rectangle fill="pink" stroke="blue" />}
                    onClick={(event, index) => handleBarClick(event, index)} // Add onClick handler
                />
            </BarChart>
            </ResponsiveContainer>
        )
    );
}
