import React, {  useEffect, useState } from 'react';
import { Select, Typography, Row, Col, Switch  } from 'antd';
import { get_companies } from '../admin/product/services/product';
const { Option } = Select;

// Define the company type
interface Company {
  id: number;
  name: string;
}

// const department = ['All', 'Chemistry ', 'Botany Zoology ', 'Physics', 'Biotechnology', 'Microbiology', 'Food Technology', 'Cancel All'];
// const companies = [ 'All','PCL', 'LOBA', 'RLCC','Cancel All'];
const uitable = ['UNIQUE FIRM', 'SOHAM FIRM'];


const CompanyDepartmentDropdown = ({ companies,setSelectedDepartmentss, setSelectedCompanyy, setSelecteduitablee,
  setGstShow,isDiscountShow,setIsDiscountShow,isGstShow,departments,selecteduitabl
 }: any) => {
  // const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [selectedDepartments, setSelectedDepartments] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>([]);
  const [selecteduitable, setSelecteduitable] = useState<any>(selecteduitabl ? selecteduitabl :'UNIQUE FIRM');
 useEffect(()=>{
  if(selecteduitabl){
    setSelecteduitable(selecteduitabl)
  }
 },[selecteduitabl])
  // Specify the parameter type for handleCompanyChange
  const handleCompanyChange = (value: string[]) => {
    // setSelectedCompany(value);
    // setSelectedCompanyy(value);

    if (value && value.length > 0) {
      if (value.includes('All')) {
        const allcompanies = companies.map((e:any) => e.id);
        setSelectedCompany(allcompanies);
        setSelectedCompanyy(allcompanies);
      } else if (value.includes('Cancel All')) {
        setSelectedCompany(null);
        setSelectedCompanyy(null);
      } else {
        setSelectedCompany(value);
        setSelectedCompanyy(value);
      }
    } else {
      setSelectedCompany(null);
      setSelectedCompanyy(null);
    }

  };

  const handleuitableChange = (value: number) => {
    setSelecteduitable(value);
    setSelecteduitablee(value);
  };
 
  // Specify the parameter type for handleDepartmentChange
  // const handleDepartmentChange = (value: string[]) => {
  //   setSelectedDepartments(value);
  //   setSelectedDepartmentss(value);
  // };
  const handleDepartmentChange = (value: string[]) => {

    if (value && value.length > 0) {
      if (value.includes('All')) {
        const allDepartments = departments.map((dept:any) => dept.id);
        setSelectedDepartments(allDepartments);
        setSelectedDepartmentss(allDepartments);
      } else if (value.includes('Cancel All')) {
        setSelectedDepartments(null);
        setSelectedDepartmentss(null);
      } else {
        setSelectedDepartments(value);
        setSelectedDepartmentss(value);
      }
    } else {
      setSelectedDepartments(null);
      setSelectedDepartmentss(null);
    }

  };

  const onChangeDiscount = (checked: boolean) => {
    setIsDiscountShow(checked)
  };
  const onChangeGst = (checked: boolean) => {
    setGstShow(checked)
  };
  

  return (
    <Row gutter={16}>
      <Col xs={12} sm={6} md={6} lg={6}>
        <Typography.Title level={4}>Select Company</Typography.Title>
        <Select
          mode="multiple"
          placeholder="Select a company"
          onChange={handleCompanyChange}
          style={{ width: '100%' }}
          value={selectedCompany}
        >
         
         <Option key={"All"} value={"All"}>
              All
            </Option>
          
          {companies && companies.length > 0 && companies.map((c:any) => (
            <Option key={"companyyyyy"+c.id} value={c.id}>
              {c.name}
            </Option>
          ))}
            <Option key={"cancelllllllll"} value={"Cancel All"}>
            Cancel All
            </Option>
        </Select>
      </Col>
      <Col xs={12} sm={7} md={7} lg={7}>
        <Typography.Title level={4}>Select Departments</Typography.Title>
        <Select
          mode="multiple"
          placeholder="Select departments"
          value={selectedDepartments}
          onChange={handleDepartmentChange}
          style={{ width: '100%' }}
        // disabled={!selectedCompany} // Disable if no company is selected
        >
          <Option key={"All"} value={"All"}>
              All
            </Option>
          {departments && departments.length > 0 && departments.map((d:any) => (
            <Option key={d.id} value={d.id}>
              {d.name}
            </Option>
          ))}
            <Option key={"cancelllllllll"} value={"Cancel All"}>
            Cancel All
            </Option>
        </Select>
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <Typography.Title level={4}>Select Firm </Typography.Title>
        <Select
          // mode="multiple"
          placeholder="Select uitable"
          value={selecteduitable}
          onChange={handleuitableChange}
          style={{ width: '100%' }}
        // disabled={!selectedCompany} // Disable if no company is selected
        >
          {uitable.map((uitable) => (
            <Option key={uitable} value={uitable}>
              {uitable}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={12} sm={5} md={5} lg={5}> <br/><br/>
        <Typography.Text >Show Discount Col: </Typography.Text>
        <Switch  onChange={onChangeDiscount} checked={isDiscountShow} /> <br/>
        <Typography.Text >Show  GST Col: </Typography.Text>
        <Switch  onChange={onChangeGst} checked={isGstShow} />
      </Col>
      <Col xs={12} sm={5} md={5} lg={5}>
      
      </Col>
    </Row>
  );
};

export default CompanyDepartmentDropdown;
