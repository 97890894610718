import React, { useState, useEffect } from 'react';
import { styled, } from '@mui/system';
import { Container, FormDiv, ButtonCust } from "../../styles/Product.style";
import { delivery_challan_list_total, delivery_challan_list } from "../../services/order";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { 
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress, Chip,  
    Typography, TextField ,FormControl,InputLabel,Select,MenuItem
} from '@mui/material';
import { useNavigate, useParams,Link } from 'react-router-dom';     
import { BUTTON_STYLE } from "../../../../../constants/style";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Button } from 'antd';

const StyledContainer = styled(Container)({
    padding: '20px',
    marginTop: '20px',
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 700,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const DeliveryChallanList: React.FC = () => {
    const navigate = useNavigate();
    const {purchaseOrderId} = useParams();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    // useEffect(() => {
        // fetchProductTotal();
    // }, []);
    // useEffect(() => {
    //     if (startDateFilter && Number(startDateFilter) > 0 && endDateFilter && Number(endDateFilter) > 0) {
    //         const formattedStartDate = new Date(Number(startDateFilter)).toISOString().split('T')[0];
    //         const formattedEndDate = new Date(Number(endDateFilter)).toISOString().split('T')[0];
            
    //         setStartDate(formattedStartDate);
    //         setEndDate(formattedEndDate);
    //     }
    // }, [startDateFilter, endDateFilter]);
    // useEffect(() => {
    //     if (startDateFilter && Number(startDateFilter) > 0 && endDateFilter && Number(endDateFilter) > 0) {
    //         // Convert the filter start and end timestamps to Date objects
    //         const startDate = new Date(Number(startDateFilter));
    //         const endDate = new Date(Number(endDateFilter));
    
    //         // Convert the Date object to UTC date and format as YYYY-MM-DD
    //         const formattedStartDate = startDate.toISOString().split('T')[0];
    //         const formattedEndDate = endDate.toISOString().split('T')[0];
    
    //         // Set the formatted dates
    //         setStartDate(formattedStartDate);
    //         setEndDate(formattedEndDate);
    //     }
    // }, [startDateFilter, endDateFilter]);
    
    useEffect(() => {
        if(page || purchaseOrderId){
            setPage(0);
         
        }
        
    }, [ rowsPerPage, purchaseOrderId]);

    useEffect(() => {
        
        fetchProductTotal();
        fetchOrders();
    }, [page, rowsPerPage, purchaseOrderId]);

    const fetchProductTotal = async () => {
        const response: any = await delivery_challan_list_total( purchaseOrderId && purchaseOrderId != "" ? purchaseOrderId : "" );
        setTotalRows(response && response.data?.deliveryChallanTotal && response.data?.deliveryChallanTotal.length > 0 
            ? response.data?.deliveryChallanTotal[0].quotationTotal : 0);
    };

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response: any = await delivery_challan_list(page + 1, rowsPerPage,
              purchaseOrderId && purchaseOrderId != "" ? purchaseOrderId : "");
            setOrders(response.data.deliveryChallan);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDetail = (deliveryChallanId: number) => {
        navigate(`/admin/delivery-challan/detail/${purchaseOrderId}/${deliveryChallanId}`);
    };

    const handleEdit = (deliveryChallanId: number) => {
        navigate(`/admin/delivery-challan/update/${purchaseOrderId}/${deliveryChallanId}`);
    };

    const handleCreate = () => {
        navigate(`/admin/delivery-challan/create/${purchaseOrderId}`);
    };


    return (
        <Container>
            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Delivery Challan List of Purchase Order No ( PO{purchaseOrderId} )</Typography>
                    <div>
                    <Link to={"/admin/purchase_order_list"}> 
                    <Button>
                        Back
                    </Button></Link> &nbsp;
                    <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                        Add
                    </ButtonCust>
                   
                    
                    
                  
                    </div>
                    
                </Box>

              

                <StyledContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <StyledTableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="medium">No.</TableCell>
                                        <TableCell size="medium">User</TableCell>
                                        <TableCell size="medium">Employee</TableCell>
                                        <TableCell size="medium"> Date</TableCell>
                                         <TableCell size="medium">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders && orders.length > 0 && orders.map((order: any, index) => (
                                        <TableRow key={order.id}>
                                            <TableCell size="small">{order.id}</TableCell>
                                            <TableCell size="small">{order.fullName}  <br/>{order.mobileNo}</TableCell>
                                            <TableCell size="small">{order.employee}</TableCell>
                                            <TableCell size="small">{new Date(order.deliveryChallanDate).toLocaleString('en-GB')}</TableCell>
                                            
                                            <TableCell size="small">
                                                <IconButton onClick={() =>handleDetail(order.id)}>
                                                    <RemoveRedEyeIcon />
                                                </IconButton>
                                            
                                                <IconButton onClick={() => handleEdit(order.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                          
                                                
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableContainer>
                    )}
                </StyledContainer>
            </FormDiv>  
        </Container>
    );
};

export default DeliveryChallanList;
