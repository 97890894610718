import React from 'react';
import {removeWord} from "../../order/constants";
const OrderViewOnetable = ({ selecteduitable, filteredProducts, roundedTotalAmount,isDiscountShow, isGstShow }: any) => {
  // Define border color conditionally
  const borderColor = selecteduitable === "SOHAM FIRM" ? "rgb(166, 166, 192)" : "rgb(175 193 123 / 49%)";
  const bgColor = selecteduitable === "SOHAM FIRM" ? "rgb(166, 166, 192)" : "rgb(175 193 123 / 78%)";

  const styles = {
    table: {
      width: '100%',
      borderCollapse: 'collapse' as 'collapse' | 'separate',
      fontFamily: 'Arial, sans-serif',
      border: `2px solid ${borderColor}`, // Dynamically set border color
    },
    tableHead: {
      backgroundColor: bgColor,
      textAlign: 'left' as const,
      color:  "#2c2c2c",
    },
    tableCell: {
      padding: '6px',
      border: `1px solid ${borderColor}`,
      fontWeight: 'bold',
      fontSize: '11px',
      textAlign: 'center' as const,
      wordWrap: 'break-word' as const,
    },
    tableCell2: {
      padding: '6px',
      border: `1px solid ${borderColor}`,
      fontSize: '11px',
      textAlign: 'center' as const,
    },
    tableCell3: {
      whiteSpace: 'normal' as const,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left' as const,
    },
    totalCell: {
      padding: '6px',
      textAlign: 'center' as const,
      fontWeight: 'bold',
      fontSize: '11px',
      borderTop: `2px solid ${borderColor}`,
    },
    totalAmount: {
      padding: '6px',
      fontWeight: 'bold',
      textAlign: 'center' as const,
      fontSize: '11px',
      borderLeft: `2px solid ${borderColor}`,
      borderTop: `2px solid ${borderColor}`,
    },
  };

  return (
    <div>
      <table style={styles.table}>
        <tbody>
          <tr style={styles.tableHead}>
            <th style={{ ...styles.tableCell, width: '5%' }}>Sr. No.</th>
            <th style={{ ...styles.tableCell, ...styles.tableCell3, width:
             isDiscountShow && isGstShow
             ? "20%"
             : isDiscountShow && !isGstShow
             ? "27%"
             :  !isDiscountShow && isGstShow  ? "27%" : "44%",
              }}>Product Code Name</th>
            <th style={{ ...styles.tableCell, width: '15%' }}>Company</th>
            <th style={{ ...styles.tableCell, width: '10%' }}>Pack</th>
            <th style={{ ...styles.tableCell, width: '10%' }}>Price (Rs.)</th>
            {
              isDiscountShow && <>
               <th style={{ ...styles.tableCell, width: '7%' }}>Disc (%)</th>
               <th style={{ ...styles.tableCell, width: '10%' }}>Disc Price (Rs.)</th>
              </>
            }
             {
              isGstShow && <>
                <th style={{ ...styles.tableCell, width: '7%' }}>GST (%)</th>
                <th style={{ ...styles.tableCell, width: '10%' }}>GST Price (Rs.)</th>
               </>
            }
           
            <th style={{ ...styles.tableCell, width: '6%' }}>Qty</th>
            <th style={{ ...styles.tableCell, width: '10%' }}>Total</th>
          </tr>
          {filteredProducts.map((product: any, index: number) => (
            <tr key={product.id}>
              <td style={{ ...styles.tableCell2, width: '5%' }}>{index + 1}</td>
              <td style={{ ...styles.tableCell2, ...styles.tableCell3,
                 width: isDiscountShow && isGstShow
                 ? "20%"
                 : isDiscountShow && !isGstShow
                 ? "27%"
                 :  !isDiscountShow && isGstShow  ? "27%" : "44%", }}>
                 {product.name && product.attribute.companyProductCode &&
                                 product.name != product.attribute.companyProductCode ?       
                                removeWord(product.name, ""+product.attribute.companyProductCode+"") :
                                product.name} 

                {product.attribute.companyProductCode && 
                product.name !=  product.attribute.companyProductCode &&
                 ` - (${product.attribute.companyProductCode})`}
              
                <br />
                {product.attribute.description}
              </td>
              <td style={{ ...styles.tableCell2, width: '15%' }}>{product.attribute.companyName}</td>
              <td style={{ ...styles.tableCell2, width: '10%' }}>
                {product.attribute.qty} {product.attribute.unitName}
              </td>
              <td style={{ ...styles.tableCell2, width: '10%' }}>
                {
                  isDiscountShow && isGstShow
                  ? product.attribute.price
                  : isDiscountShow && !isGstShow
                  ? product.attribute.price
                  :  !isDiscountShow && isGstShow  ?
                   product.attribute.discountAmount : product.attribute.gstAmount
                }
               
                
                </td>

              {
              isDiscountShow && <>
             <td style={{ ...styles.tableCell2, width: '7%' }}>{product.attribute.discount}%</td>
              <td style={{ ...styles.tableCell2, width: '10%' }}>{product.attribute.discountAmount}</td>
                  </>
            }
             {
              isGstShow && <>
               <td style={{ ...styles.tableCell2, width: '7%' }}>{product.attribute.gst_percentage}%</td>
              <td style={{ ...styles.tableCell2, width: '10%' }}>{product.attribute.gstAmount}</td>
             </>
            }

              <td style={{ ...styles.tableCell2, width: '6%' }}>{product.attribute.quotationQty}</td>
              <td style={{ ...styles.tableCell2, width: '10%' }}>{product.attribute.totalPrice}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={isDiscountShow && isGstShow
             ? 10
             : isDiscountShow && !isGstShow
             ? 8
             :  !isDiscountShow && isGstShow  ? 8 : 6
              } style={styles.totalCell}>
              Total Amount
            </td>
            <td style={styles.totalAmount}>
            {(filteredProducts.reduce((total: any, product: any) => Number(total) +
                    (Number(product.attribute.totalPrice)), 0)).toFixed(2)}
              {/* {roundedTotalAmount} */}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderViewOnetable;
