import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { get_user, create_user } from "../services/order";
import AddIcon from "@mui/icons-material/Add";

interface User {
  id: string;
  fullName: string;
}

const UserSearch = ({ selectedUserId, setSelectedUserId }: any) => {
  const [options, setOptions] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    fullName: "",
    mobileNo: "",
    addressInfo: "",
    email: "",
    GSTnumber: ""
  });
  const [errors, setErrors] = useState({
    fullName: "",
    mobileNo: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await get_user(); // Fetch users from API
        setOptions(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event: any, newValue: User | null) => {
    setSelectedUserId(newValue); // Update selected user
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // Clear the error when user starts typing
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;
    let validationErrors: any = {};

    if (!newUser.fullName) {
      validationErrors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!newUser.mobileNo) {
      validationErrors.mobileNo = "Mobile No is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(newUser.mobileNo)) {
      validationErrors.mobileNo = "Mobile No must be 10 digits";
      isValid = false;
    }

    setErrors(validationErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const data:any = await create_user(newUser); // Call your API to create the user
        if(data && data.data && data.data.insertId > 0){
          setOptions([...options,{
             ...newUser,
             id:data.data.insertId
          }])
          setSelectedUserId({
            ...newUser,
            id:data.data.insertId
         });
        }else{
          alert(data?.data?.result)
        }
        handleDialogClose();
      } catch (error:any) {
        alert(error?.message)
        handleDialogClose();  
        console.error("Error creating user:", error);
      }
    }
  };
  return (
    <div style={{display:"flex"}}>
      <Autocomplete
      style={{width:"95%"}}
        value={selectedUserId ? selectedUserId : {}}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        options={options}
        getOptionLabel={(option: User) => (option.fullName ? option.fullName : "")}
        loading={loading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search User"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {/* Add Plus Button to open dialog */}
      <IconButton onClick={handleDialogOpen} aria-label="add" sx={{ marginLeft: 2 }}>
        <AddIcon />
      </IconButton>

      {/* Dialog for creating new user */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Full Name"
            name="fullName"
            value={newUser.fullName}
            onChange={handleInputChange}
            margin="normal"
            error={!!errors.fullName}
            helperText={errors.fullName}
          />
          <TextField
            fullWidth
            label="Mobile No"
            name="mobileNo"
            value={newUser.mobileNo}
            onChange={handleInputChange}
            margin="normal"
            error={!!errors.mobileNo}
            helperText={errors.mobileNo}
          />
          <TextField
            fullWidth
            label="Address Info"
            name="addressInfo"
            value={newUser.addressInfo}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="GST Number"
            name="GSTnumber"
            value={newUser.GSTnumber}
            onChange={handleInputChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained"
           disabled={loading} color="primary" 
           >
            Create User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserSearch;
