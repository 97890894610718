import React, { useState, useEffect } from 'react';
import { styled, } from '@mui/system';
import { Container, FormDiv, ButtonCust } from "../styles/Product.style";
import { quotation_list, order_list_total, order_detail } from "../services/order";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Tooltip from '@mui/material/Tooltip';
import { 
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress, Chip,  
    Typography, TextField ,FormControl,InputLabel,Select,MenuItem
} from '@mui/material';
import { useNavigate, useParams,useLocation } from 'react-router-dom';     
import { BUTTON_STYLE } from "../../../../constants/style";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import * as XLSX from "xlsx";
const StyledContainer = styled(Container)({
    padding: '20px',
    marginTop: '20px',
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 700,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const ProductList: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { startDateFilter,endDateFilter, day,month,year } = useParams();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [searchInput, setSearchInput] = useState<string>('');
    const [quotationId, setQuotationId] = useState<string>('');
    const [orderStatus, setOrderStatus] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [loadingXlxs, setLoadingXlxs] = useState(false);
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const grantedValue = searchParams.get("Granted");
        console.log("Granted:", grantedValue);
        if (grantedValue) {
          setOrderStatus("Granted");
        }
      }, []);
    useEffect(() => {
        if (startDateFilter && Number(startDateFilter) > 0 && endDateFilter && Number(endDateFilter) > 0) {
            // Convert the filter start and end timestamps to Date objects
            const startDate = new Date(Number(startDateFilter));
            const endDate = new Date(Number(endDateFilter));
    
            // Convert the Date object to UTC date and format as YYYY-MM-DD
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];
    
            // Set the formatted dates
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        }
    }, [startDateFilter, endDateFilter]);
    
    useEffect(() => {
        if(page ||  startDate ||  endDate || searchInput || quotationId || orderStatus || status 
            || day || month || year
        ){
            setPage(0);
         
        }
        
     }, [ rowsPerPage, startDate, endDate, searchInput,quotationId, orderStatus, status, day,month,year]);


    useEffect(() => {
    
        fetchProductTotal();
        fetchOrders();
    }, [page, rowsPerPage, startDate, endDate, searchInput,quotationId, orderStatus, status, day,month,year]);

    const fetchProductTotal = async () => {
        const response: any = await order_list_total(startDate ? ""+new Date(startDate).getTime()+"" : "" ,
         endDate ? ""+new Date(endDate).getTime()+"" : "",
        searchInput && searchInput != "" ? searchInput : "",
        quotationId && quotationId != "" ? quotationId : "",
        orderStatus && orderStatus !== ""? orderStatus : "",
        status && status !== ""? status : "",day,month,year
    );
        setTotalRows(response && response.data?.quotationstotal && response.data?.quotationstotal.length > 0 
            ? response.data?.quotationstotal[0].quotationTotal : 0);
    };

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response: any = await quotation_list(page + 1, rowsPerPage, 
              startDate ? ""+new Date(startDate).getTime()+"" : "" , endDate ? ""+new Date(endDate).getTime()+"" : "",
              searchInput && searchInput != "" ? searchInput : "",
              quotationId && quotationId != "" ? quotationId : "",
              orderStatus && orderStatus !== ""? orderStatus : "",
                 status && status !== ""? status : "",
                 day,month,year
            );
            setOrders(response.data.quotations);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDetail = (productId: number) => {
        navigate(`/admin/quotation_edit/${productId}`);
    };

    const handleEdit = (productId: number) => {
        navigate(`/admin/quotation_update/${productId}`);
    };

    const handleCreate = () => {
        navigate(`/admin/quotation/create`);
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const exportXlsxSheet = async (id:any) => {
        console.log(id)
        setLoadingXlxs(true)
        const response: any = await order_detail(id);
        let products = response?.data?.quotationProducts;
        if(products && products.length > 0){
        const filteredData = products.map((item:any) => ({
           "name" : item.productName,
           "companyProductCode":item.companyProductCode,
           "unit":item.qty +" "+item.unitName, 
           "qty":item.quotationQty,
           "CompanyName":item.companyName,
           "discount":item.discount, 
           "gst_percentage":item.gst_percentage, 
           "department":item.departmentName ? item.departmentName : "",
            }));

        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Quotation Products");
        XLSX.writeFile(workbook, `Quotation_Products_UBC-QU-${id}.xlsx`);
        
        }
        setLoadingXlxs(false);
    }
    const exportXlsxSheet2 = async (id:any) => {
        console.log(id)
        setLoadingXlxs(true)
        const response: any = await order_detail(id);
        let products = response?.data?.quotationProducts;
        if(products && products.length > 0){
        const filteredData = products.map((item:any) => ({
           "name" : item.productName,
           "companyProductCode":item.companyProductCode,
           "unit":item.qty +" "+item.unitName, 
           "qty":item.quotationQty,
           "CompanyName":item.companyName,
           "discount":item.discount, 
           "gst_percentage":item.gst_percentage, 
           "department":item.departmentName ? item.departmentName : "",
           "qoutation_product_id" : item.id,
           "qoutation_id":item.quotationId
            }));

        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Quotation Products");
        XLSX.writeFile(workbook, `PO_of_Quotation_Products_UBC-QU-${id}.xlsx`);
        
        }
        setLoadingXlxs(false);
    }
    return (
        <Container>
            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Quotation List</Typography>
                    <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                        Add
                    </ButtonCust>
                </Box>{
                    day && day != "0" && <label>Date:{day}-{month}-{year}</label>
                }

                {/* Start and End Date Filters */}
                <Box sx={{ display: 'flex', gap: '16px', marginTop: '20px' }}>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate || ''}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate || ''}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                    <TextField
                        label="Users Name"
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        type="text"
                        size="small"
                        placeholder="Search your Users"
                    />
                     <TextField
                        label=" Quotation Id"
                        variant="outlined"
                        value={quotationId}
                        onChange={(e) => setQuotationId(e.target.value)}
                        type="text"
                        size="small"
                        placeholder="Search Quotation Id"
                    />

                        <FormControl 
                        style={{width:"200px"}}
                             size="small"
                        >
                        <InputLabel>
                            Status
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={(e) => setStatus(e.target.value)}
                        >  <MenuItem value={""}>None</MenuItem>

                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Processing"}>Processing</MenuItem>
                        <MenuItem value={"Shipped"}>Shipped</MenuItem>
                        <MenuItem value={"Completed"}>Completed</MenuItem>
                        </Select>
                        </FormControl>
                        <FormControl 
                        style={{width:"200px"}}
                        size="small"
                        >
                        <InputLabel>
                            Order Status
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orderStatus}
                            label="Order Status"
                            onChange={(e) => setOrderStatus(e.target.value)}
                        >
                             <MenuItem value={""}>None</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Granted"}>Granted</MenuItem>
                        </Select>
                        </FormControl>
                   
                </Box>

                <StyledContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <StyledTableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="medium">Q.No.</TableCell>
                                        <TableCell size="medium">Q.Ref.No.</TableCell>
                                        <TableCell size="medium">Firm</TableCell>
                                        <TableCell size="medium">User</TableCell>
                                        <TableCell size="medium">Employee</TableCell>
                                        <TableCell size="medium"> Date</TableCell>
                                        <TableCell size="medium">Qty</TableCell>
                                        <TableCell size="medium">Total Price</TableCell>
                                        <TableCell size="medium">Location</TableCell>
                                        <TableCell size="medium">Status</TableCell>
                                        <TableCell size="medium">Order Status</TableCell>
                                        <TableCell size="medium">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders && orders.length > 0 && orders.map((order: any, index) => (
                                        <TableRow key={order.id}>
                                            <TableCell size="small">{
                      order.id  ? <>UBC-QU-{order.id}</> : "-"
                    }</TableCell>
                                            <TableCell size="small">
                                            {
                      order.referenceQuotationId  ? <>UBC-QU-{order.referenceQuotationId}</> : "-"
                    }</TableCell>
                                            
                                           <TableCell size="small">{order.firm}</TableCell>
                                            <TableCell size="small">{order.fullName}  <br/>{order.mobileNo}</TableCell>
                                            <TableCell size="small">{order.employee}</TableCell>
                                            <TableCell size="small">{new Date(order.quotationDate).toLocaleDateString('en-GB')}</TableCell>
                                            <TableCell size="small">{order.totalQty}</TableCell>
                                            <TableCell size="small">{order.totalPrice}</TableCell>
                                            <TableCell size="small">{order.location}</TableCell>
                                            <TableCell size="small">
                                                <Chip label={order.status} color="primary" size="small" />
                                            </TableCell>
                                            <TableCell size="small">
                                                <Chip label={order.orderStatus ? order.orderStatus : "None"}
                                                 color={
                                                    order.orderStatus  && order.orderStatus === "Granted" ? 
                                                     "success" : "primary"} size="small" />
                                            </TableCell>
                                            
                                            <TableCell size="small">
                                                 <Tooltip title="Quotation Detail">
                                                <IconButton onClick={() =>handleDetail(order.id)}>
                                                    <RemoveRedEyeIcon />
                                                </IconButton>
                                            </Tooltip>
                                             <Tooltip title="Quotation Edit">
                                                <IconButton onClick={() => handleEdit(order.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Export for create new qoutation">
                                                <IconButton onClick={() => exportXlsxSheet(order.id)}
                                                    disabled={loadingXlxs}>
                                                    <ArrowCircleDownIcon 
                                                    />
                                                  </IconButton>
                                                 </Tooltip>

                                                <Tooltip title="Export for P.O">
                                                <IconButton onClick={() => exportXlsxSheet2(order.id)}
                                                    disabled={loadingXlxs}>
                                                    <ArrowCircleDownIcon 
                                                    />
                                                  </IconButton>
                                                 </Tooltip>
                                                
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableContainer>
                    )}
                </StyledContainer>
            </FormDiv>  
        </Container>
    );
};

export default ProductList;
