import React, { useState, useEffect } from 'react';
import {
  Button, Box, Grid, Typography, FormControl,
  TextField, InputLabel, Select, MenuItem, IconButton, Autocomplete
} from '@mui/material';
import { Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import WarningIcon from '@mui/icons-material/Warning';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Container, FormDiv, ButtonCust } from "../styles/Product.style";
import { BUTTON_STYLE, CANCEL_BUTTON_STYLE } from "../../../../constants/style"
import { useNavigate, useParams } from 'react-router-dom';
import { get_companies } from "../../product/services/product";
import {
  get_units, get_gst_rates, fetch_batch_update_product, fetch_batch_product,
  order_detail, update_order, fetch_departments,get_quotation_ids
} from "../services/order";
import CustomizedSnackbars from "../../../reusable/CustomizedSnackbars";
import * as XLSX from "xlsx";
import { sanitizeHTMLContent } from "../constants/index";
import Quilleditor from '../../../reusable/Quilleditor';
import UserSearch from "./UserSearch";
import { defaultDepartmentId, IMG_BASE_URL, insertQuotationAttriCount, insertQuotationAttriFetchCount, WEB_BASE_URL } from '../../../../constants';
import NotExitsProductsModal from './NotExitsProductsModal';
import DepartmentTabs from "./DepartmentTabs";

interface OptionType {
  value: string;
  label: string;
}

const options: OptionType[] = [
  { value: 'UNIQUE', label: 'UNIQUE' },
  { value: 'SOHAM', label: 'SOHAM' },
];

const OrderForm: React.FC = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { orderId } = useParams();
  const [visibleProducts, setVisibleProducts] = useState(25);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [withGst, setWithGst] = useState(2);
  const [excelLoading, setExcelLoading] = useState(false);
  const [status, setStatus] = useState("Pending");
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState("1000000000");
  const [orderStatus, setOrderStatus] = useState("Pending");
  const [units, setUnits] = useState([]);
  const [alreadyAddedQuotationPro, setAlreadyAddedQuotationPro] = useState<any>([]);
  const [termsAndConditions, setTermsAndConditions] = useState<any>(null);
  const [bankDetails, setbankDetails] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [dateOfEntry, setDateOfEntry] = useState<Dayjs | null>(null);
  const [orderDate, setOrderDate] = useState<Dayjs | null>(null);
  const [enquiryDate, setEnquiryDate] = useState<Dayjs | null>(null);
  const [sendingDate, setSendingDate] = useState<Dayjs | null>(null);
  const [enquiryEmail, setEnquiryEmail] = useState(null);
  const [enquiryMobileNo, setEnquiryMobileNo] = useState(null);
  const [enquiryNo, setEnquiryNo] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState<any>({});
  const [duplicates, setDuplicate] = useState<any>([]);
  const [order, setOrder] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [openSnackBars, setOpenSnackBars] = useState(false);
  const [gstRates, setGstRates] = useState([]);
  const [notExitsOrderData, setNotExitsOrderData] = useState<any>([]);
  const [openExitsModal, setOpenExitsModal] = useState(false);
  const [departments, setDepartment] = useState<any>([]);
  const [quotationIds, setQuotationIds] = useState([]);
  const [quotationId,setQuotationId] = useState<OptionType | null>(null);
  const [firm,setFirm] = useState<OptionType | null>(null);
  useEffect(() => {
    fetchUnits();
    fetch_companies();
    fetchGstRates();
    get_departments();
    fetch_quotationIds();
  }, []);
  useEffect(() => {
    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);
  const fetchOrderDetail = async () => {
    const responseDepartment: any = await fetch_departments();
    setExcelLoading(true);
    const response: any = await order_detail(orderId);
    setOrder(response?.data?.purchase_order || {});
    setTermsAndConditions(response?.data?.purchase_order?.termsAndConditions)
    setbankDetails(response?.data?.purchase_order?.bankDetails)
    setSelectedUserId({ id: response?.data?.purchase_order?.userId, fullName: response?.data?.purchase_order?.fullName })
    setDateOfEntry(response?.data?.purchase_order?.dateOfEntry
      ? dayjs(response.data.purchase_order.dateOfEntry)
      : null)
    setOrderDate(response?.data?.purchase_order?.orderDate
      ? dayjs(response.data.purchase_order.orderDate)
      : null)
    setFirm({label:response?.data?.purchase_order?.firm,value:response?.data?.purchase_order?.firm})  
    setLocation(response?.data?.purchase_order?.location)
    setEnquiryDate(response?.data?.purchase_order?.enquiryDate
      ? dayjs(response.data.purchase_order.enquiryDate)
      : null)
    setSendingDate(response?.data?.purchase_order?.sendingDate
      ? dayjs(response.data.purchase_order.sendingDate)
      : null)
    setEnquiryEmail(response?.data?.purchase_order?.enquiryEmail)
    setQuotationId({value:response?.data?.purchase_order?.quotationId,label:'UBC-QU-'+response?.data?.purchase_order?.quotationId})
    setEnquiryMobileNo(response?.data?.purchase_order?.enquiryMobileNo)
    setEnquiryNo(response?.data?.purchase_order?.enquiryNo)
    setData(response?.data?.quotationProducts)
    setOrderStatus(response?.data?.purchase_order?.orderStatus)
    setStatus(response?.data?.purchase_order?.status)
    const transformedData = response?.data?.quotationProducts?.map((item: any, index: any) => {
      return {
        id: index + 1,
        name: item.productName,
        attribute: {
          "id": item.id,
          "purchaseOrderId": item.purchaseOrderId,
          "userId": item.userId,
          "qty": item.qty,
          "productId": item.productId,
          "companyId": item.companyId,
          "price": item.price,
          "landingPrice": item.landingPrice,
          "discount": item.discount,
          "discountValue": item.discountValue,
          "discountAmount": item.discountAmount,
          "gstAmount": item.gstAmount,
          "totalPrice": item.totalPrice,
          "createdDttm": item.createdDttm,
          "gst_percentage": item.gst_percentage,
          "companyProductCode": item.companyProductCode,
          "gstValue": item.gstValue,
          "quotationQty": item.quotationQty,
          "productName": item.productName,
          "subCategoryName": item.subCategoryName,
          "categoryName": item.categoryName,
          "companyName": item.companyName,
          "unitName": item.unitName,
          "unitId": item.unitId,
          "srno": item.srno,
          "departmentId": item.departmentId
        },
        quotationQty: item.quotationQty
      }
    }) || [];
    setAlreadyAddedQuotationPro([...transformedData]);
    let departmentNewData = responseDepartment.data?.data;
    let quotationDepartmentSort = response?.data?.departments;
    if (departmentNewData && departmentNewData.length > 0) {
      departmentNewData = departmentNewData.map((e: any) => {
        if (quotationDepartmentSort && quotationDepartmentSort.length > 0) {
          let dsort = quotationDepartmentSort.find((f: any) => f.id == e.id);
          e.orderSequence = dsort?.sortingNo;
          e.deptEmpMobileNo = dsort?.deptEmpMobileNo;
          e.deptEmpName= dsort?.deptEmpName;
        }
        let d = { ...e };
        return d;
      })
      setDepartment([...departmentNewData])
    }
    setExcelLoading(false);
  };
  const fetch_companies = async () => {
    try {
      const response: any = await get_companies();
      setCompanies(response.data.data)
    } catch (error) {
    }
  }
  const fetch_quotationIds = async () => {
    try {
      const response: any = await get_quotation_ids();
      setQuotationIds(response.data?.quotations)
    } catch (error) {
    }
  }
  // const onSelectToOrder = (product:any) => {
  //   setErrorMessage("")
  //   let exitingProduct  = orderProducts.find((e:any)=>e.attribute.id == product.attribute.id)
  //   if(!exitingProduct || true){
  //     let srno = alreadyAddedQuotationPro && alreadyAddedQuotationPro.length > 0  ?  
  //     (alreadyAddedQuotationPro[alreadyAddedQuotationPro.length - 1].attribute.srno > 0 ?
  //       (alreadyAddedQuotationPro[alreadyAddedQuotationPro.length - 1].attribute.srno + orderProducts.length + 1) 
  //       : ( orderProducts.length + 1))
  //     : (orderProducts.length + 1)
  //     let attributes =  {...product.attribute,srno: srno };
  //     let newProduct: any = { ...product,attribute:attributes }; // Create a copy of the product
  //     newProduct.quotationQty = 1;
  //     let newObj = calculatePrice(attributes,newProduct.quotationQty)
  //     newProduct.attribute = newObj;
  //     let updatedOrderProducts = [...orderProducts,newProduct]; // Copy the existing orderProducts array
  //      
  //   }

  // } 

  const fetchUnits = async () => {
    try {
      const response: any = await get_units();
      setUnits(response.data?.data)
    } catch (error) {

    }
  }
  const fetchGstRates = async () => {
    try {
      const response: any = await get_gst_rates();
      setGstRates(response.data?.data)
    } catch (error) {

    }
  }
  const get_departments = async () => {
    // try {
    //   const response: any = await fetch_departments();
    //   setDepartment(response.data.data)
    // } catch (error) {
    // }

  }




  const createOrderFun = async () => {
    setLoading(true);
    let orderProducts: any = [];
    let quotationDepartments: any = [];
    const duplicates: any = [];
    let newDepartMent: any = departments.map((e: any) => {
      const duplicatesDepartmentWise: any = [];
      const unique: any = [];
      if (e.orderProducts && e.orderProducts.length > 0) {
        e.orderProducts.forEach((product: any, index: any) => {
          e.orderProducts[index] = {
            ...product,
            attribute: { ...product.attribute, departmentId: e.id }
          }
          const attribute = product.attribute;
          const priceIsInvalid = !(attribute.price > 0);
          const UnitIsInvalid = !(attribute.unitId > 0);
          const finalPriceIsInvalid = !(attribute.totalPrice > 0);
          if (priceIsInvalid || UnitIsInvalid || finalPriceIsInvalid) {
            duplicatesDepartmentWise.push({ ...product });
            duplicates.push({ ...product });
          } else {
            unique.push(product);
          }
        });
        orderProducts = [...orderProducts, ...e.orderProducts]
        // setVisibleProducts(25);
        if (duplicatesDepartmentWise && duplicatesDepartmentWise.length > 0) {
          let newDataSortDuplicates = duplicatesDepartmentWise.sort((a: any, b: any) => a.attribute.id - b.attribute.id)
          const sortedOrderProducts = [...newDataSortDuplicates, ...unique];
          e.orderProducts = [...sortedOrderProducts];
        }

      }
      quotationDepartments.push({
        departmentId: e.id,
        sortingNo: e.orderSequence ? e.orderSequence : departments.length + 1,
        deptEmpMobileNo: e.deptEmpMobileNo ? e.deptEmpMobileNo : "",
        deptEmpName: e.deptEmpName ? e.deptEmpName : ""
      })
      return e;
    })
    setDepartment([...newDepartMent]);
    setDuplicate([...duplicates]);
    if (selectedUserId && selectedUserId.id > 0 &&
      dateOfEntry && enquiryDate && enquiryMobileNo &&
      String(enquiryMobileNo).length >= 10 && enquiryEmail && orderId && Number(orderId) > 0) {
      if (orderProducts && orderProducts.length > 0) {

        if ((duplicates && duplicates.length === 0)) { //not check duplicates just show duplicates
          setErrorMessage("")
          let payload: any = {};
          payload = {
            "purchase_order": {
              "dateOfEntry": dateOfEntry ? dateOfEntry.valueOf() : "",
              "orderDate": orderDate ? orderDate.valueOf() : "",
              "enquiryDate": enquiryDate ? enquiryDate.valueOf() : "",
              "sendingDate": sendingDate ? sendingDate.valueOf() : "",
              "enquiryEmail": enquiryEmail ? enquiryEmail : "",
              "quotationId":quotationId?.value ?  quotationId?.value:"",
              "firm":firm ?firm.value : "",
              "enquiryMobileNo": enquiryMobileNo ? enquiryMobileNo : "",
              "enquiryNo": enquiryNo ? enquiryNo : "",
              "termsAndConditions": termsAndConditions && termsAndConditions !== "" ?
                sanitizeHTMLContent(termsAndConditions) : "",
              "bankDetails": bankDetails && bankDetails !== "" ?
                sanitizeHTMLContent(bankDetails) : "",
              "status": status,
              "orderStatus": orderStatus,
              "location": location,
              "withGst": withGst,
              "userId": selectedUserId.id
            },
            "updateQuotationProducts": orderProducts.filter((e: any) => e.attribute.purchaseOrderId &&
              e.attribute.purchaseOrderId > 0 && e.attribute.isUpdate).map((product: any) => ({
                "rowId": product.attribute.id,
                "purchaseOrderId": product.attribute.purchaseOrderId,
                "productId": product.attribute.productId,
                "companyId": product.attribute.companyId,
                "companyProductCode": product.attribute.companyProductCode,
                "qty": product.attribute.qty,
                "quotationQty": product.quotationQty,
                "unitId": product.attribute.unitId,
                "price": product.attribute.price,
                "landingPrice": product.attribute.landingPrice,
                "productUnitCode": product.attribute.productUnitCode,
                "departmentId": product.attribute.departmentId,
                "discount": product.attribute.discount,
                "discountValue": product.attribute.discountValue,
                "discountAmount": product.attribute.discountAmount,
                "gst_percentage": product.attribute.gst_percentage,
                "gstValue": product.attribute.gstValue,
                "gstAmount": product.attribute.gstAmount,
                "totalPrice": product.attribute.totalPrice,
              })),
            "createQuotationProducts": orderProducts.filter((e: any) => !e.attribute.purchaseOrderId).map((product: any) => ({
              "rowId": product.attribute.purchaseOrderId,
              "purchaseOrderId": product.attribute.id,
              "productId": product.attribute.productId,
              "companyId": product.attribute.companyId,
              "companyProductCode": product.attribute.companyProductCode,
              "qty": product.attribute.qty,
              "quotationQty": product.quotationQty,
              "unitId": product.attribute.unitId,
              "price": product.attribute.price,
              "landingPrice": product.attribute.landingPrice,
              "productUnitCode": product.attribute.productUnitCode,
              "departmentId": product.attribute.departmentId,
              "discount": product.attribute.discount,
              "discountValue": product.attribute.discountValue,
              "discountAmount": product.attribute.discountAmount,
              "gst_percentage": product.attribute.gst_percentage,
              "gstValue": product.attribute.gstValue,
              "gstAmount": product.attribute.gstAmount,
              "totalPrice": product.attribute.totalPrice,
              "srno": product.attribute.srno,
            }))
          };
          try {
            if (payload.createQuotationProducts && payload.createQuotationProducts.length > 0) {
              let chunkedProductArray = Array.from(
                { length: Math.ceil(payload.createQuotationProducts.length / insertQuotationAttriCount) },
                (_, i) => payload.createQuotationProducts.slice(i * insertQuotationAttriCount, i * insertQuotationAttriCount + insertQuotationAttriCount)
              );

              for (const qutatation of chunkedProductArray) {
                let payloadInsertBatchWise = {
                  purchase_order: payload.purchase_order,
                  createQuotationProducts: qutatation,
                  updateQuotationProducts: [],
                  quotationDepartments: quotationDepartments
                };
                await update_order(payloadInsertBatchWise, orderId);
              }

            }
            if (payload.updateQuotationProducts && payload.updateQuotationProducts.length > 0) {
              let chunkedProductArray2 = Array.from(
                { length: Math.ceil(payload.updateQuotationProducts.length / insertQuotationAttriCount) },
                (_, i) => payload.updateQuotationProducts.slice(i * insertQuotationAttriCount, i * insertQuotationAttriCount + insertQuotationAttriCount)
              );
              for (const qutatation of chunkedProductArray2) {
                let payloadInsertBatchWise = {
                  purchase_order: payload.purchase_order,
                  createQuotationProducts: [],
                  updateQuotationProducts: qutatation,
                  quotationDepartments: quotationDepartments
                };
                await update_order(payloadInsertBatchWise, orderId);

              }
            }
            if ((payload.updateQuotationProducts && payload.updateQuotationProducts.length === 0) &&
              (payload.createQuotationProducts && payload.createQuotationProducts.length === 0)) {
              let payloaddata = {
                purchase_order: payload.purchase_order,
                createQuotationProducts: [],
                updateQuotationProducts: [],
                quotationDepartments: quotationDepartments
              };
              await update_order(payloaddata, orderId);
            }

          } catch (error) {
            console.error("Error processing purchase_order:", error);
          } finally {
            // Ensure this part always runs after the loop completes
            setLoading(false);
            window.location.href = WEB_BASE_URL + "admin/purchase_order_list"
          }


        } else {
          setLoading(false);
          setErrorMessage("Please avoid adding duplicate products,  and ensure that the price is not empty or zero.")
        }
      } else {
        let payload: any = {};
        payload = {
          "purchase_order": {
            "dateOfEntry": dateOfEntry ? dateOfEntry.valueOf() : "",
            "orderDate": orderDate ? orderDate.valueOf() : "",
            "enquiryDate": enquiryDate ? enquiryDate.valueOf() : "",
            "sendingDate": sendingDate ? sendingDate.valueOf() : "",
            "enquiryEmail": enquiryEmail ? enquiryEmail : "",
            "quotationId":quotationId?.value ?  quotationId?.value:"",
            "firm":firm ?firm.value : "",
            "enquiryMobileNo": enquiryMobileNo ? enquiryMobileNo : "",
            "enquiryNo": enquiryNo ? enquiryNo : "",
            "termsAndConditions": termsAndConditions && termsAndConditions !== "" ?
              sanitizeHTMLContent(termsAndConditions) : "",
            "bankDetails": bankDetails && bankDetails !== "" ?
              sanitizeHTMLContent(bankDetails) : "",
            "status": status,
            "orderStatus": orderStatus,
            "location": location,
            "withGst": withGst,
            "userId": selectedUserId.id
          },
          "updateQuotationProducts": [],
          "createQuotationProducts": [],
          quotationDepartments: quotationDepartments
        };
        await update_order(payload, orderId);
        setLoading(false);
        window.location.href = WEB_BASE_URL + "admin/purchase_order_list"
      }
    } else {
      setLoading(false);
      setErrorMessage(`Please select user,  Date of Entry, Order Date, Enquiry Date, Enquiry Mobile No, Enquiry Email
           and ensure that the price is not empty or zero.`)
    }




  }


  // Handle file upload
  const handleFileUpload = async (event: any) => {
    setExcelLoading(true)
    const file = event.target.files[0];
    const expectedColumns = ["name", "companyProductCode", "unit", "qty", "CompanyName",
      "discount", "gst_percentage", "department"];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        // Validate column names
        const headerRow: any = worksheet[0]; // Get the header row
        const isValidHeader = expectedColumns.every((col, index) => headerRow[index] === col) &&
          expectedColumns.length === headerRow.length;
        if (!isValidHeader) {
          setExcelLoading(false);
          setErrorMessage("Invalid file format. Please upload a file with the correct column names and it's sequence needs to match. Please check sample file.");
          return; // Stop further processing if the column names don't match
        } else {
          setErrorMessage("");
        }
        let isValidData = false;
        // Convert rows
        const parsedRows: any = worksheet.slice(1).map((row: any, index: any) => {
          if (!row[0] || !(row[3] && row[3] > 0) || !row[2] || !row[2]) {
            isValidData = true;
          }
          let departmentId = defaultDepartmentId;
          if (row[7]) {
            let departmentObj = departments.find((e: any) => e.name == row[7])
            departmentId = departmentObj && departmentObj?.id ? departmentObj.id : defaultDepartmentId
          }
          return {
            name: row[0],
            companyProductCode: row[1],
            unit: row[2],
            qty: row[3],
            company_name: row[4],
            discount: row[5],
            gst_percentage: row[6],
            srno: alreadyAddedQuotationPro && alreadyAddedQuotationPro.length > 0 ?
              (alreadyAddedQuotationPro[alreadyAddedQuotationPro.length - 1].attribute.srno > 0 ?
                (alreadyAddedQuotationPro[alreadyAddedQuotationPro.length - 1].attribute.srno + index + 1) : (index + 1))
              : (index + 1),
            departmentId: !row[7] ? defaultDepartmentId : departmentId
          }
        });
        if (isValidData || parsedRows.length > 400) {
          setExcelLoading(false);
          setErrorMessage(isValidData ?
            "Invalid file data. Please upload a file with the correct values of name, companyProductCode, unit ,price."
            : "Please upload only 400 products!");
          return;
        }

        setErrorMessage("")
        let chunkedProductArray = Array.from(
          { length: Math.ceil(parsedRows.length / insertQuotationAttriFetchCount) },
          (_, i) => parsedRows.slice(i * insertQuotationAttriFetchCount, i * insertQuotationAttriFetchCount + insertQuotationAttriFetchCount)
        );
        let outputdata: any = [];
        let notExitsData: any = [];
        for (const qutatation of chunkedProductArray) {
          let payload = {
            data: qutatation,
            orderId: orderId
          }
          //  const newData:any =   await fetch_batch_update_product(payload,orderId);  
          const newData: any = await fetch_batch_product(payload);
          if (newData && newData.data?.data && newData.data?.data.length > 0) {
            let finalData = newData.data?.data.filter((e: any) => !e.attribute.isNotExits)
            let notExitsFinalData = newData.data?.data.filter((e: any) => e.attribute.isNotExits)
            outputdata = [...outputdata, ...finalData];
            notExitsData = [...notExitsData, ...notExitsFinalData]
          }
        }
        let newDepartmentData = departments.map((d: any) => {
          d.orderProducts = outputdata.filter((f: any) => f.attribute?.departmentId == d.id)
          return d
        })
        setDepartment([...newDepartmentData])
        setNotExitsOrderData([...notExitsData]);
        setExcelLoading(false);
      };
      reader.readAsArrayBuffer(file);
    }

  };


  return (
    <Container>
      {contextHolder}
      <CustomizedSnackbars
        message={"Quotation created successfully."}
        openSnackBars={openSnackBars}
        setOpenSnackBars={setOpenSnackBars}
      />
      <FormDiv>
        <Typography>Purchase Order Update
          <a href={IMG_BASE_URL + "sampleQuotationImport.xlsx"}><label style={{
            fontSize: "13px",
            color: "#199bff",
            textDecorationLine: "underline",
            marginLeft: "10px",
            "cursor": "pointer"
          }}>Download Sample Example File</label></a>
        </Typography>  <br />
        <div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              {
                order?.id && order?.id > 0 && <>
                  <Button variant="contained" component="label" disabled={loading}>
                    Upload File
                    <input type="file" hidden onChange={handleFileUpload} accept=".xlsx, .xls" />
                  </Button>
                  {
                    notExitsOrderData && notExitsOrderData.length > 0 && <IconButton aria-label="delete" onClick={() => setOpenExitsModal(true)}>
                      <WarningIcon />
                    </IconButton>
                  }
                </>
              }
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
                       <Autocomplete
                       size="small"
                            value={firm}
                            onChange={(event: any, newValue: OptionType | null) => {
                              setFirm(newValue);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Firm" />}
                          />
                        </Grid>
             <Grid item xs={12} sm={12} md={2}>
                        <Autocomplete
                        size="small"
                            value={quotationId}
                            onChange={(event: any, newValue: OptionType | null) => {
                              setQuotationId(newValue);
                            }}
                            id="controllable-states-demo"
                            options={quotationIds}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Quotation" />}
                          />
                        </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={
                    <>
                      Date of Entry
                      <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={dateOfEntry}
                  onChange={(newValue) => setDateOfEntry(newValue)}
                  slotProps={{ textField: { size: "small" } }}

                />
              </LocalizationProvider>

            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    <>
                      Order Date
                    
                    </>
                  }
                  value={orderDate}
                  onChange={(newValue) => setOrderDate(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker

                  label={
                    <>
                      Enquiry Date
                      <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={enquiryDate}
                  onChange={(newValue) => setEnquiryDate(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    <>
                      Sending Date
                    </>
                  }
                  value={sendingDate}
                  onChange={(newValue) => setSendingDate(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextField
                label={<>
                  Enquiry Mobile No
                  <span style={{ color: "red" }}>*</span>
                </>}
                InputLabelProps={{
                  shrink: enquiryMobileNo ? true : false, // Ensure label stays above the input when it has a value
                }}
                id="EnquiryMobileNo"
                type="number"
                size="small"
                value={enquiryMobileNo}
                onChange={(e: any) => setEnquiryMobileNo(e.target.value)}
                variant="outlined"
                onWheel={(e) => {
                  (e.target as HTMLInputElement).blur(); // ✅ TypeScript Safe
                }}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                style={{ width: "100%" }}
                label={<>
                  Enquiry Email
                  <span style={{ color: "red" }}>*</span>
                </>}
                InputLabelProps={{
                  shrink: enquiryEmail ? true : false, // Ensure label stays above the input when it has a value
                }}
                id="enquiryEmail"
                type="text"
                size="small"
                value={enquiryEmail}
                onChange={(e: any) => setEnquiryEmail(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                label={<>
                  Enquiry No
                  <span style={{ color: "red" }}>*</span>
                </>}
                InputLabelProps={{
                  shrink: enquiryNo ? true : false, // Ensure label stays above the input when it has a value
                }}
                id="enquiryNo"
                type="text"
                size="small"
                value={enquiryNo}
                onChange={(e: any) => setEnquiryNo(e.target.value)}
                variant="outlined"

              />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <UserSearch
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId} />

            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <span>Terms & Conditions :</span>
              <Quilleditor value={termsAndConditions} setValue={setTermsAndConditions} />
             
            </Grid>

            {/* Bank */}
            <Grid item xs={12} sm={12} md={6}>
              <span>Bank Details :</span>
              <Quilleditor value={bankDetails} setValue={setbankDetails} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth size="small">
                <TextField
                  id="outlined-multiline-static"
                  label="Location"
                  InputLabelProps={{
                    shrink: location ? true : false, // Ensure label stays above the input when it has a value
                  }}
                  multiline
                  rows={4}
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value)
                  }}
                />
              </FormControl>
            </Grid>

          </Grid>

        </div>

        <Grid container spacing={2}>
          <Grid item sm={12} md={12} lg={12}>
            {
              excelLoading && <>
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "250px" }}>
                  {/* <CircularProgress />  */}
                  Loading...
                </Box>
              </>
            }<br />
          </Grid>
        </Grid>

        {
          !excelLoading && departments && departments.length > 0 && <>
            <DepartmentTabs
              departments={departments}
              gstRates={gstRates} companies={companies}
              units={units}
              setDepartment={setDepartment}
              duplicates={duplicates}
              alreadyAddedQuotationPro={alreadyAddedQuotationPro}

            />
          </>
        }


        <br />
        <Grid container spacing={2} >
          <Grid item sm={3} md={6}> </Grid>

          <Grid item sm={12} md={3}>
            <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
              <FormControl fullWidth

              >
                <InputLabel>
                  Status
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"Processing"}>Processing</MenuItem>
                  <MenuItem value={"Shipped"}>Shipped</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                </Select>
              </FormControl>
            </Box>

          </Grid>
          <Grid item sm={12} md={3}>
            <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
              <FormControl fullWidth

              >
                <InputLabel>
                  Order Status
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderStatus}
                  label="Order Status"
                  onChange={(e) => setOrderStatus(e.target.value)}
                >
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"Granted"}>Granted</MenuItem>
                </Select>
              </FormControl>
            </Box>

          </Grid>
        </Grid>
        <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
          {/* {
              orderProducts && orderProducts.length > 0 ? 
                <>
                <Typography variant="body1" sx={{marginRight:"20x"}} >
                Qty: <b>
                { orderProducts.reduce((total: any, product: any) => {
    const quotationQty = Number(product.quotationQty);
    return Number(total) + quotationQty;
}, 0) }
                  </b>
 &nbsp; &nbsp;
                  Total: <b>{(orderProducts.reduce((total:any, product:any) => Number(total) + 
                (Number(product.attribute.totalPrice)), 0) ).toFixed(2)}</b>
                              </Typography>
              
                </> : null
            } */}

        </Box>
        <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
          <label style={{ color: "red" }}>{errorMessage}</label>
        </Box>

        <ButtonCust sx={BUTTON_STYLE} onClick={createOrderFun} disabled={loading}>
          Submit {loading && "Loading ..... please wait"}
        </ButtonCust> &nbsp;
        <ButtonCust sx={CANCEL_BUTTON_STYLE} onClick={() => {
          navigate('/admin/purchase_order_list');
        }}>
          Cancel
        </ButtonCust>
      </FormDiv>




      <Modal
        title="Not Exits products list"
        centered
        open={openExitsModal}
        onOk={() => setOpenExitsModal(false)}
        onCancel={() => setOpenExitsModal(false)}
        width={800}
      >
        <NotExitsProductsModal productData={notExitsOrderData} />
      </Modal>
    </Container>
  );
};
export default OrderForm;
